// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Float from "@rescript/core/src/Core__Float.mjs";
import * as SingleMetricDrilldownForProjectQuery_graphql from "../__generated__/SingleMetricDrilldownForProjectQuery_graphql.mjs";

var parse = SingleMetricDrilldownForProjectQuery_graphql.Utils.metricEnum_fromString;

function serialize(t) {
  return t;
}

var MetricParam = {
  parse: parse,
  serialize: serialize
};

function parse$1(str) {
  var match = str.split("::");
  if (match.length !== 4) {
    return ;
  }
  var match$1 = match[0];
  if (match$1 !== "timelineForProject") {
    return ;
  }
  var fromRaw = match[1];
  var toRaw = match[2];
  var metricRaw = match[3];
  var match$2 = Core__Float.fromString(fromRaw);
  var match$3 = Core__Float.fromString(toRaw);
  var match$4 = parse(metricRaw);
  if (match$2 !== undefined && match$3 !== undefined && match$4 !== undefined) {
    return {
            from: match$2,
            to: match$3,
            metric: match$4
          };
  }
  
}

function serialize$1(t) {
  return "timelineForProject::" + t.from.toString() + "::" + t.to.toString() + "::" + t.metric;
}

var Config = {
  parse: parse$1,
  serialize: serialize$1
};

export {
  MetricParam ,
  Config ,
}
/* SingleMetricDrilldownForProjectQuery_graphql Not a pure module */
