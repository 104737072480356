// Generated by ReScript, PLEASE EDIT WITH CARE

import * as U from "../utils/U.mjs";
import * as $$Text from "../components/Text.mjs";
import * as Icons from "../Icons.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.mjs";
import * as ReactHelmet from "react-helmet";
import * as FramerMotion from "framer-motion";
import * as RelayRouter__Link from "rescript-relay-router/src/RelayRouter__Link.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function makeBreadcrumb(href, text) {
  return {
          href: href,
          text: text
        };
}

function PageTitle$BreadcrumbItem(props) {
  var first = props.first;
  return JsxRuntime.jsx("li", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      first ? Icons.homeOutline("flex-shrink-0 h-5 w-5 mr-2 mb-0.5 text-gray-400") : Icons.chevronRight("flex-shrink-0 h-5 w-5 text-gray-400"),
                      JsxRuntime.jsx(RelayRouter__Link.make, {
                            to_: props.href,
                            className: U.tw([
                                  "text-sm font-medium text-gray-500 hover:text-gray-700",
                                  first ? "" : "ml-4"
                                ]),
                            children: props.text
                          })
                    ],
                    className: "flex items-center"
                  })
            });
}

var BreadcrumbItem = {
  make: PageTitle$BreadcrumbItem
};

function PageTitle(props) {
  var afterTitle = props.afterTitle;
  var __titleTemplate = props.titleTemplate;
  var actions = props.actions;
  var breadcrumbs = props.breadcrumbs;
  var shortTitle = props.shortTitle;
  var title = props.title;
  var titleTemplate = __titleTemplate !== undefined ? __titleTemplate : "%s | Informind";
  var shortTitle$1 = shortTitle !== undefined ? shortTitle : title;
  var lastItem = Core__Option.getOr(breadcrumbs, []).slice().pop();
  return JsxRuntime.jsxs(FramerMotion.motion.div, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        lastItem !== undefined ? JsxRuntime.jsx("nav", {
                                children: JsxRuntime.jsxs(RelayRouter__Link.make, {
                                      to_: lastItem.href,
                                      className: "flex items-center text-sm font-medium text-gray-500 hover:text-gray-700",
                                      children: [
                                        Icons.chevronLeft("flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"),
                                        "Back"
                                      ]
                                    }),
                                "aria-label": "Back",
                                className: "sm:hidden"
                              }) : null,
                        JsxRuntime.jsx("nav", {
                              children: JsxRuntime.jsxs("ol", {
                                    children: [
                                      Core__Option.getOr(breadcrumbs, []).map(function (breadcrumb, i) {
                                            return JsxRuntime.jsx(PageTitle$BreadcrumbItem, {
                                                        href: breadcrumb.href,
                                                        first: i === 0,
                                                        text: breadcrumb.text
                                                      }, String(i));
                                          }),
                                      lastItem !== undefined ? JsxRuntime.jsx(PageTitle$BreadcrumbItem, {
                                              href: "#",
                                              first: false,
                                              text: shortTitle$1
                                            }) : null
                                    ],
                                    className: "flex items-center space-x-4"
                                  }),
                              "aria-label": "Breadcrumb",
                              className: "hidden sm:flex"
                            })
                      ]
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx($$Text.LargeTitle.make, {
                                      title: title,
                                      layoutId: props.titleLayoutId
                                    }),
                                afterTitle !== undefined ? Caml_option.valFromOption(afterTitle) : null
                              ],
                              className: "flex-1 flex-row flex min-w-0"
                            }),
                        JsxRuntime.jsx(ReactHelmet.Helmet, {
                              children: JsxRuntime.jsx("title", {
                                    children: Core__Option.getOr(props.overridePageTitle, title)
                                  }),
                              titleTemplate: titleTemplate
                            }),
                        actions !== undefined ? JsxRuntime.jsx("div", {
                                children: Caml_option.valFromOption(actions),
                                className: "mt-4 flex-shrink-0 flex md:mt-0 md:ml-4"
                              }) : null
                      ],
                      className: "mt-2 md:flex md:items-center md:justify-between"
                    }),
                Core__Option.getOr(props.subContent, null)
              ],
              layout: props.layout,
              layoutId: props.layoutId
            });
}

var make = PageTitle;

export {
  makeBreadcrumb ,
  BreadcrumbItem ,
  make ,
}
/* Text Not a pure module */
